var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row mt-2 mb-4 flex" }, [
        _c(
          "div",
          { staticClass: "vx-col w-2/6 grid grid-flow-row auto-rows-max" },
          [
            _c("label", { staticClass: "font-bold" }, [_vm._v("Avaliação:")]),
            _vm.praticalTestName
              ? _c("span", {}, [
                  _vm._v(" " + _vm._s(_vm.praticalTestName) + " "),
                ])
              : _vm._e(),
          ]
        ),
        _vm.isClassroomSetUp
          ? _c(
              "div",
              { staticClass: "vx-col w-4/6" },
              [
                _c(
                  "vx-input-group",
                  [
                    _c("vs-input", {
                      attrs: {
                        id: "classroomIdentifier",
                        label: _vm.$t("classroom.room"),
                      },
                      on: { change: _vm.onChangeClassroomIdentifier },
                      model: {
                        value: _vm.classroomIdentifier,
                        callback: function ($$v) {
                          _vm.classroomIdentifier = $$v
                        },
                        expression: "classroomIdentifier",
                      },
                    }),
                    _vm.isClassroomTeacherSetUp
                      ? _c("template", { slot: "append" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.auto",
                                  value: {
                                    content: "Solicitar suporte",
                                    delay: { show: 500, hide: 500 },
                                  },
                                  expression:
                                    "{content: 'Solicitar suporte', delay: {show: 500,hide: 500}}",
                                  modifiers: { auto: true },
                                },
                              ],
                              staticClass:
                                "append-text btn-addon flex items-end",
                            },
                            [
                              _c("vs-button", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "classrooms.support_request",
                                    expression: "'classrooms.support_request'",
                                  },
                                ],
                                attrs: {
                                  color: "primary",
                                  type: "border",
                                  "icon-pack": "feather",
                                  icon: "icon-alert-triangle",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addClassroomSuppportRequest()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: true,
                        expression: "true",
                      },
                    ],
                    staticClass: "text-sm",
                    class: _vm.classroomIdentifierStyle,
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.classroomIdentifierConfigLabel) + " "
                    ),
                  ]
                ),
                _c(
                  "vs-popup",
                  {
                    attrs: {
                      title: _vm.$t("classroom.support"),
                      active: _vm.promptSuportRequest,
                      "button-close-hidden": true,
                    },
                  },
                  [
                    _c("vs-textarea", {
                      attrs: { label: _vm.$t("classroom.support_description") },
                      model: {
                        value: _vm.supportDescription,
                        callback: function ($$v) {
                          _vm.supportDescription = $$v
                        },
                        expression: "supportDescription",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "w-full flex gap-2 justify-end" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { disabled: !_vm.isSupportDescriptionValid },
                            on: { click: _vm.addSuppportRequest },
                          },
                          [_vm._v(_vm._s(_vm.$t("action.request")))]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "ml-2",
                            attrs: { type: "border" },
                            on: {
                              click: function ($event) {
                                _vm.promptSuportRequest = false
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("action.cancel")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "Grid",
        {
          key: _vm.key,
          ref: "grid",
          attrs: {
            hide_actions: "true",
            hide_searchbar: "true",
            service: _vm.praticalTestService,
            route_name: "practical_tests",
            fetchOnMounted: false,
            route_grid_path:
              _vm.id + "/teachers/" + _vm.teacherId + "/students/grid",
          },
          on: { "grid-UPDATED": _vm.gridLoaded },
          scopedSlots: _vm._u([
            {
              key: "gridThead",
              fn: function () {
                return [
                  _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                    _vm._v("ID"),
                  ]),
                  _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                    _vm._v(_vm._s(_vm.$t("practical_test.student.name"))),
                  ]),
                  _c(
                    "vs-th",
                    { key: "identifier", attrs: { "sort-key": "identifier" } },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("practical_test.student.identifier"))
                      ),
                    ]
                  ),
                  _c("vs-th", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid grid-cols-3 justify-items-center flex w-full",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _c(
                              "vs-chip",
                              {
                                staticClass: "text-base",
                                attrs: { transparent: "", color: "success" },
                              },
                              [_vm._v("Não iniciada")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _c(
                              "vs-chip",
                              {
                                staticClass: "text-base",
                                attrs: { transparent: "", color: "warning" },
                              },
                              [_vm._v("Iniciada")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _c(
                              "vs-chip",
                              {
                                staticClass: "text-base",
                                attrs: { transparent: "", color: "primary" },
                              },
                              [_vm._v("Finalizada")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "gridData",
              fn: function (props) {
                return _vm._l(props.data, function (tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr, attrs: { data: tr } },
                    [
                      _c(
                        "vs-td",
                        {
                          key: "id",
                          staticClass: "break-all p-1 pl-4",
                          attrs: { width: "5%" },
                        },
                        [_c("div", [_vm._v(" " + _vm._s(tr.id) + " ")])]
                      ),
                      _c(
                        "vs-td",
                        {
                          key: "name",
                          staticClass: "break-all p-1 pl-4",
                          attrs: { width: "25%" },
                        },
                        [_c("div", [_vm._v(" " + _vm._s(tr.data.name))])]
                      ),
                      _c(
                        "vs-td",
                        {
                          key: "identifier",
                          staticClass: "break-all p-1 pl-4",
                          attrs: { width: "15%" },
                        },
                        [_c("div", [_vm._v(" " + _vm._s(tr.data.identifier))])]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { width: "60%" } },
                        [
                          _c("vs-table", {
                            attrs: { data: _vm.sections },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var data = ref.data
                                    return _vm._l(
                                      data,
                                      function (trSection, i) {
                                        return _c(
                                          "vs-tr",
                                          {
                                            key: data[i],
                                            class: _vm.classSection(
                                              trSection,
                                              tr.data
                                            ),
                                            staticStyle: {
                                              "border-bottom": "thin solid",
                                            },
                                          },
                                          [
                                            _c(
                                              "vs-td",
                                              { attrs: { width: "70%" } },
                                              [
                                                _c("div", {
                                                  directives: [
                                                    {
                                                      name: "html-safe",
                                                      rawName: "v-html-safe",
                                                      value:
                                                        trSection.data.title,
                                                      expression:
                                                        "trSection.data.title",
                                                    },
                                                  ],
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "vs-td",
                                              { attrs: { width: "30%" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grid grid-cols-2",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        _c("feather-icon", {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip.auto",
                                                              value: {
                                                                content:
                                                                  "Iniciar avaliação",
                                                                delay: {
                                                                  show: 500,
                                                                  hide: 500,
                                                                },
                                                              },
                                                              expression:
                                                                "{content: 'Iniciar avaliação', delay: {show: 500,hide: 500}}",
                                                              modifiers: {
                                                                auto: true,
                                                              },
                                                            },
                                                          ],
                                                          attrs: {
                                                            icon: "PlayIcon",
                                                            svgClasses:
                                                              "h-7 w-7 mb-1 mr-3 stroke-current cursor-pointer",
                                                          },
                                                          on: {
                                                            click: function () {
                                                              return _vm.openPraticalTest(
                                                                tr,
                                                                trSection.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "justify-center items-center space-y-1 p-1",
                                                        class:
                                                          _vm.getStatisticsClass(
                                                            _vm.getStatisticsAnalyticTotalQuestion(
                                                              trSection,
                                                              tr.data
                                                            ),
                                                            _vm.getStatisticsAnalyticTotalQuestionAnswered(
                                                              trSection,
                                                              tr.data
                                                            )
                                                          ),
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip.auto",
                                                                value: {
                                                                  content:
                                                                    "Total de Itens",
                                                                  delay: {
                                                                    show: 500,
                                                                    hide: 500,
                                                                  },
                                                                },
                                                                expression:
                                                                  "{ content: 'Total de Itens', delay: {show: 500, hide: 500}}",
                                                                modifiers: {
                                                                  auto: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass: "flex",
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "ListIcon",
                                                                svgClasses:
                                                                  "h-5 w-5",
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getStatisticsAnalyticTotalQuestion(
                                                                      trSection,
                                                                      tr.data
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip.auto",
                                                                value: {
                                                                  content:
                                                                    "Total de Itens Avaliados",
                                                                  delay: {
                                                                    show: 500,
                                                                    hide: 500,
                                                                  },
                                                                },
                                                                expression:
                                                                  "{ content: 'Total de Itens Avaliados', delay: {show: 500, hide: 500}}",
                                                                modifiers: {
                                                                  auto: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass: "flex",
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "CheckIcon",
                                                                svgClasses:
                                                                  "h-5 w-5",
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getStatisticsAnalyticTotalQuestionAnswered(
                                                                      trSection,
                                                                      tr.data
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c("template", { slot: "filter" }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("vs-input", {
                  attrs: {
                    id: "student_identifier",
                    label: _vm.$t("practical_test.student.identifier"),
                  },
                  model: {
                    value: _vm.identifier,
                    callback: function ($$v) {
                      _vm.identifier = $$v
                    },
                    expression: "identifier",
                  },
                }),
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-4",
                    staticStyle: { "margin-top": "20px !important" },
                    attrs: { type: "border", disabled: _vm.identifier === "" },
                    on: { click: _vm.filter },
                  },
                  [_vm._v("Pesquisar")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-3",
                    staticStyle: { "margin-top": "20px !important" },
                    attrs: { type: "border" },
                    on: { click: _vm.goBack },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }