<template>
  <div>
    <div class="vx-row mt-2 mb-4 flex">
      <div class="vx-col w-2/6 grid grid-flow-row auto-rows-max">
        <label class="font-bold">Avaliação:</label>
        <span class="" v-if="praticalTestName">
          {{ praticalTestName }}
        </span>
      </div>
      <div class="vx-col w-4/6" v-if="isClassroomSetUp">
        <vx-input-group>
          <vs-input
            id="classroomIdentifier"
            :label="$t('classroom.room')"
            v-model="classroomIdentifier"
            v-on:change="onChangeClassroomIdentifier">
            </vs-input>
            <template slot="append" v-if="isClassroomTeacherSetUp">
              <div class="append-text btn-addon flex items-end" v-tooltip.auto="{content: 'Solicitar suporte', delay: {show: 500,hide: 500}}">
                <vs-button
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-alert-triangle"
                  @click="addClassroomSuppportRequest()"
                  v-permission="'classrooms.support_request'">
                </vs-button>
              </div>
            </template>
        </vx-input-group>
        <span class="text-sm" v-show="true" :class="classroomIdentifierStyle">
          {{classroomIdentifierConfigLabel}}
        </span>
        <!-- Entrance delay justification form popup -->
        <vs-popup
          :title="$t('classroom.support')"
          :active="promptSuportRequest"
          :button-close-hidden="true">
          <vs-textarea :label="$t('classroom.support_description')" v-model="supportDescription" />
          <div class="w-full flex gap-2 justify-end">
            <vs-button
              :disabled="!isSupportDescriptionValid"
              @click="addSuppportRequest">{{ $t('action.request') }}</vs-button>
            <vs-button
              class="ml-2"
              type="border"
              @click="promptSuportRequest = false">
              {{ $t('action.cancel') }}
            </vs-button>
          </div>
        </vs-popup>
      </div>
    </div>
    <!-- GRID-->
    <Grid
      ref="grid"
      :key="key"
      hide_actions="true"
      hide_searchbar="true"
      :service="praticalTestService"
      route_name="practical_tests"
      :fetchOnMounted="false"
      @grid-UPDATED="gridLoaded"
      :route_grid_path="`${id}/teachers/${teacherId}/students/grid`">

      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('practical_test.student.name') }}</vs-th>
        <!-- <vs-th key="email" sort-key="email">{{ $t('practical_test.student.email') }}</vs-th>-->
        <vs-th key="identifier" sort-key="identifier">{{ $t('practical_test.student.identifier') }}</vs-th>
        <vs-th>
          <div class="grid grid-cols-3 justify-items-center flex w-full">
            <div class="flex justify-center">
              <vs-chip transparent color="success" class="text-base">Não iniciada</vs-chip>
            </div>
            <div class="flex justify-center">
              <vs-chip transparent color="warning" class="text-base">Iniciada</vs-chip>
            </div>
            <div class="flex justify-center">
              <vs-chip transparent color="primary" class="text-base">Finalizada</vs-chip>
            </div>
          </div>
        </vs-th>
      </template>

      <template slot="filter">
        <div class="flex">
          <vs-input
            id="student_identifier"
            :label="$t('practical_test.student.identifier')"
            v-model="identifier">
          </vs-input>

            <vs-button
              class="ml-4"
              style="margin-top: 20px !important;"
              type="border"
              :disabled="identifier === ''"
              @click="filter">Pesquisar</vs-button>
            <vs-button
              class="ml-3"
              style="margin-top: 20px !important;"
              type="border"
              @click="goBack">
              {{ $t('common.back') }}
            </vs-button>

        </div>
      </template>

      <template v-slot:gridData="props">

        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id" class="break-all p-1 pl-4">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="25%" key="name" class="break-all p-1 pl-4">
            <div> {{ tr.data.name }}</div>
          </vs-td>
          <vs-td width="15%" key="identifier" class="break-all p-1 pl-4">
            <div> {{ tr.data.identifier }}</div>
          </vs-td>
          <vs-td width="60%">

            <vs-table :data="sections">
              <template slot-scope="{data}">
                <vs-tr
                  :key="data[i]"
                  v-for="(trSection, i) in data"
                  :class="classSection(trSection, tr.data)"
                  style="border-bottom: thin solid;">
                  <vs-td width="70%">
                    <div v-html-safe="trSection.data.title" class=""></div>
                  </vs-td>
                  <vs-td width="30%">
                    <div class="grid grid-cols-2">
                      <div class="flex">
                        <feather-icon
                          icon="PlayIcon"
                          svgClasses="h-7 w-7 mb-1 mr-3 stroke-current cursor-pointer"
                          v-tooltip.auto="{content: 'Iniciar avaliação', delay: {show: 500,hide: 500}}"
                          @click="() => openPraticalTest(tr, trSection.id)"/>

                      </div>
                      <div class="justify-center items-center space-y-1 p-1" :class="getStatisticsClass(getStatisticsAnalyticTotalQuestion(trSection, tr.data), getStatisticsAnalyticTotalQuestionAnswered(trSection, tr.data))">
                        <div class="flex" v-tooltip.auto="{ content: 'Total de Itens', delay: {show: 500, hide: 500}}">
                          <feather-icon icon="ListIcon" svgClasses="h-5 w-5"/>
                          <span class="ml-1">{{getStatisticsAnalyticTotalQuestion(trSection, tr.data)}}</span>
                        </div>
                        <div class="flex" v-tooltip.auto="{ content: 'Total de Itens Avaliados', delay: {show: 500, hide: 500}}">
                          <feather-icon icon="CheckIcon" svgClasses="h-5 w-5"/>
                          <span class="ml-1">{{getStatisticsAnalyticTotalQuestionAnswered(trSection, tr.data)}}</span>
                        </div>
                      </div>
                    </div>
                  </vs-td>

                </vs-tr>
              </template>
            </vs-table>

          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import PracticalTestStudentService from '@/services/api/PracticalTestStudentService'
import PracticalTestService from '@/services/api/PracticalTestService'
import PracticalTestTeacherService from '@/services/api/PracticalTestTeacherService'
import ClassroomConfigService from '@/services/api/ClassroomConfigService'
import ClassroomService from '@/services/api/ClassroomService'

export default {
  props: {
    id: { // PraticalTestId
      default: null,
      type: Number
    },
    teacherId: { // PraticalTestTeacherId
      default: null,
      type: Number
    },
    identifier: {
      default: '',
      type: String
    },
  },
  data: () => ({
    service: null,
    praticalTestService: null,
    practicalTestTeacherService: null,
    classroomConfigService: null,
    classroomService: null,
    //Data
    sections : [],
    praticalTestName: null,
    questionnaireId: null,
    classroomIdentifier: null,
    classroom: null,
    supportDescription: null,
    promptSuportRequest: false,
  }),
  computed: {
    isClassroomSetUp() {
      return this.classroom && this.classroom.classroom_config_id !== null
    },
    isClassroomTeacherSetUp() {
      return this.classroom && _.get(this.classroom, 'classroom_teacher_id', null) !== null
    },
    classroomIdentifierStyle() {
      if (this.isClassroomTeacherSetUp) {
        return 'text-success' // Estado válido
      }  else {
        return 'text-danger' // Estado inválido
      }
    },
    classroomIdentifierConfigLabel() {
      if (this.isClassroomTeacherSetUp) {
        return `Sala configurada: ${  _.get(this.classroom, 'name_identifier', '')}`
      } else if (!this.isClassroomTeacherSetUp && this.classroomIdentifier) {
        return 'Sala não configurada/encontrada!'
      } else {
        return 'Sala ainda não informada!'
      }
    },
    isSupportDescriptionValid() {
      return this.supportDescription !== null && this.supportDescription.length > 5
    }
  },
  methods: {
    goBack() {
      this.$router.push('/practical_tests/tests')
    },
    openPraticalTest(entity, sectionId) {
      const practicalTestStudentId = entity.id//To preserve url filter

      this.$router.history.push({ name: 'practical_test_teacher_student', query: {identifier:this.identifier}})
      //?identifier=${this.identifier}
      if (this.isClassroomSetUp) {
        this.setClassroomStudent(entity.user_id)
      }

      this.$router.push(`/form_practical/${practicalTestStudentId}/${sectionId}`)
    },
    gridLoaded(response) {
      this.sections = this._.get(response, 'sections')
      if (response.model.total === 0) {
        this.notifyWarning(this.$vs, this.$t('nenhum-registro-encontrado'), 4000)
      }
    },
    filter() {
      this.$refs['grid'].fillCustomFilters([{key: 'identifier', value: this.identifier}])
      this.$refs['grid'].fetchGridData()
      this.updateRouteInFilter()
    },
    classSection(section, data) {
      const s = this.getSectionAnalytic(data, section)
      //console.log('s...', s)
      // return s !== null && s.started_at !== null
      if (s !== null) {
        //if (s.started_at !== null && s.answered_questions.length === s.qty_question) {
        if (s.finished_at && s.finished_at !== null) {
          return 'section-finished'
        } else if (s.started_at !== null) {
          return 'section-started'
        } else {
          return 'section-not-started'
        }
      }
      return 'section-not-started'
    },
    getStatisticsAnalyticTotalQuestion(section) {
      return (section && section.qtyQuestion) ? section.qtyQuestion : 0
    },
    getStatisticsAnalyticTotalQuestionAnswered(section, data) {
      const s = this.getSectionAnalytic(data, section)

      return (s && s.answered_questions) ? s.answered_questions.length : 0
    },
    getStatisticsClass(total, qtyAnswered) {
      return total !== qtyAnswered ? 'vs-chip-danger con-color bg-chip-transparent rounded shadow' : ''
    },
    getSectionAnalytic(data, section) {
      const analytic = JSON.parse(data.analytic)
      //console.log('analytic...', analytic)
      if (!analytic) return null
      const s = analytic.sections.filter(item => {
        return item.id === section.id
      }).shift()
      return s
    },
    updateRouteInFilter() {
      //this.$router.push({ name: 'practical_test_teacher_student', query: {identifier:this.identifier}});
    },
    loadClassroomData(questionnaireId) {
      this.$vs.loading()

      const payload = {
        questionnaire_id: questionnaireId
      }

      this.classroomConfigService.getClassroomByTeacher(payload).then(
        response => {
          const hasClassroom = _.get(response, 'classroom_config_id', null)
          if (hasClassroom) {
            this.classroomIdentifier = response.identifier
            this.classroom = response
          }
        },
        error => {}
      ).finally(() => { this.$vs.loading.close() }
      )
    },
    onChangeClassroomIdentifier() {
      this.setClassroomTeacher()
    },
    setClassroomTeacher() {
      this.$vs.loading()

      const payload = {
        questionnaire_id: this.questionnaireId,
        classroom_identifier: this.classroomIdentifier
      }
      const classroomConfigId = this.classroom.classroom_config_id

      this.classroomConfigService.setClassroomTeacher(classroomConfigId, payload).then(
        response => {
          this.classroom = response
          const isSetUpSuccess = _.get(response, 'classroom_teacher_id', false)
          if (isSetUpSuccess) {
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          } else {
            this.notifyError(this.$vs, this.$t('classroom.identifier_not_valided'), 6000)
          }
        },
        error => {
          this.classroom.classroom_teacher_id = null
        }
      ).finally(() => { this.$vs.loading.close() }
      )
    },
    setClassroomStudent(userId) {
      this.$vs.loading()

      const payload = {
        user_id: userId,
        questionnaire_id: this.questionnaireId,
        classroom_identifier: this.classroomIdentifier
      }
      const classroomConfigId = this.classroom.classroom_config_id

      this.classroomConfigService.setClassroomStudent(classroomConfigId, payload).then(
        response => {

        },
        error => {}
      ).finally(() => { this.$vs.loading.close() }
      )
    },
    addClassroomSuppportRequest() {
      this.promptSuportRequest = true
    },
    addSuppportRequest() {
      this.$vs.loading()

      const payload = {
        questionnaire_id: this.questionnaireId,
        classroom_identifier: this.classroomIdentifier,
        classroom_config_id: this.classroom.classroom_config_id,
        classroom_id: this.classroom.classroom_id,
        support_description: this.supportDescription
      }

      const classroomId = this.classroom.classroom_id

      this.classroomService.addSuppportRequest(classroomId, payload).then(
        response => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.supportDescription = null
          this.promptSuportRequest = false
        },
        error => {
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(() => { this.$vs.loading.close() }
      )
    },
  },
  mounted() {
    const queryParamIdentifier = this._.get(this.$router.history.current.query, 'identifier')

    if (this.identifier !== '') {
      this.filter()
    } else if (queryParamIdentifier && queryParamIdentifier !== '') {
      this.identifier = queryParamIdentifier
      this.filter()
    }

    this.practicalTestTeacherService.read(this.teacherId).then(
      response => {
        this.praticalTestName = this._.get(response, 'practical_test.questionnaire_name')
        this.questionnaireId = this._.get(response, 'practical_test.questionnaire_id')

        this.loadClassroomData(this.questionnaireId)
      })

  },
  beforeMount() {
    this.service = PracticalTestStudentService.build(this.$vs)
    this.praticalTestService = PracticalTestService.build(this.$vs)
    this.practicalTestTeacherService = PracticalTestTeacherService.build(this.$vs)
    this.classroomConfigService = ClassroomConfigService.build(this.$vs)
    this.classroomService = ClassroomService.build(this.$vs)
  }
}
</script>

<style lang="scss">
  .section-not-started {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1);
  }

  .section-started {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1);
  }

  .section-finished {
    background: rgba(var(--vs-primary),.15);
    color: rgba(var(--vs-primary),1);
  }

  .play-color {
    color: white;
  }

  .section-text-color {
    color: white;
  }
</style>
