import Rest from '../Rest'

export default class ClassroomConfigService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/classroom_configs'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getClassroomToPanel(configId: Number) {
    return this.get(`/${configId}/classrooms/panel_list`)
  }

  getClassroomByTeacher(payload: any) {
    return this.get(`/classrooms_by_teacher`, payload)
  }

  setClassroomTeacher(configId: Number, payload: any){
    return this.put(`${configId}/classrooms/teachers`, payload)
  }

  setClassroomStudent(configId: Number, payload: any){
    return this.put(`${configId}/classrooms/students`, payload)
  }

}
